import "./scss/app.scss";
import "swiper/css/swiper.css";

import Vue from "vue";
import registerVueDirectives from "./directives";
import VueAwesomeSwiper from "vue-awesome-swiper";
import VueRellax from "vue-rellax";
import Vuebar from "vuebar";
import Vue2TouchEvents from "vue2-touch-events";
// eslint-disable-next-line
import yaMetrics from './utils/yaMetrics'

registerVueDirectives();

Vue.use(VueAwesomeSwiper);
Vue.use(VueRellax);
Vue.use(Vuebar);
Vue.use(Vue2TouchEvents);

const files = require.context("./components", true, /\.vue$/i);
files
  .keys()
  .map((key) =>
    Vue.component(key.split("/").pop().split(".")[0], files(key).default)
  );

new Vue({
  data() {
    return {
      menuOpened: false,
    };
  },
  beforeDestroy() {
    this.removeMenuLinkEvent();
  },
  methods: {
    toggleMenu() {
      this.menuOpened = !this.menuOpened;

      if (this.menuOpened) {
        this.$nextTick(() => {
          this.setMenuLinkEvent();
        });
      } else {
        this.removeMenuLinkEvent();
      }
    },
    closeMenu() {
      this.menuOpened = false;
    },
    setMenuLinkEvent() {
      const nav = document.querySelector(".header__nav");

      if (nav) {
        const links = nav.querySelectorAll("a");

        links.forEach((link) => {
          link.addEventListener("click", this.closeMenu);
        });
      }
    },
    removeMenuLinkEvent() {
      const nav = document.querySelector(".header__nav");

      if (nav) {
        const links = nav.querySelectorAll("a");

        links.forEach((link) => {
          link.removeEventListener("click");
        });
      }
    }
  },
}).$mount("#app");
